import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Toolbar from '@/components/toolbar/toolbar';
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import Swal from 'sweetalert2'

export default {
    name: "Product",
    data() {
        return {
            product_list: null,
            chk3: false,
            current_color1: { id: 3, name_color: "gray-light", img: require('@/assets/img/Home/example_glass_03.png'), type: 1, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: true }, { id: 3, name: "เสียง", value: true }], thickness: "5", vlt: "80", vlr_out: "83", vlr_in: "85", det: "55", er: "43", ea: "18", sc: "0.98", shgc: "76", lsg: "0.55", u_value: "3.59", rhg: "7.12", price: "1" },
            current_color2: { id: 1, name_color: "gray-dark", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
            list_build: [
                {
                  id: 1,
                  name: this.$t("product.findYourGlass.lowBuilding"),
                  img: require("@/assets/img/Home/i-build-sm.png"),
                  width_icon: "40",
                  type_glass: [],
                  type_color: [],
                  range_protection: [
                    {
                      id: 1,
                      name: this.$t("product.findYourGlass.properLight"),
                      value: 3,
                    },
                    {
                      id: 2,
                      name: this.$t("product.findYourGlass.properHeatProtection"),
                      value: 4,
                    },
                  ],
                  protection: [
                    { id: 1, name: this.$t("product.findYourGlass.safety"), value: true },
                    {
                      id: 2,
                      name: this.$t("product.findYourGlass.UVProtection"),
                      value: false,
                    },
                    {
                      id: 3,
                      name: this.$t("product.findYourGlass.noiseReducer"),
                      value: true,
                    },
                  ],
                },
                {
                  id: 2,
                  name: this.$t("product.findYourGlass.highBuilding"),
                  img: require("@/assets/img/Home/i-build-lg.png"),
                  width_icon: "30",
                  type_glass: [],
                  type_color: [],
                  range_protection: [
                    {
                      id: 1,
                      name: this.$t("product.findYourGlass.properLight"),
                      value: 3,
                    },
                    {
                      id: 2,
                      name: this.$t("product.findYourGlass.properHeatProtection"),
                      value: 4,
                    },
                  ],
                  protection: [
                    { id: 1, name: this.$t("product.findYourGlass.safety"), value: true },
                    {
                      id: 2,
                      name: this.$t("product.findYourGlass.UVProtection"),
                      value: false,
                    },
                    {
                      id: 3,
                      name: this.$t("product.findYourGlass.noiseReducer"),
                      value: true,
                    },
                  ],
                },
              ],
              type_glass: [
                {
                  id: 1,
                  name: "อาคารขนาดเล็ก",
                  list_glass: [
                    { id: 1, name: this.$t("product.findYourGlass.sheet") },
                    { id: 2, name: this.$t("product.findYourGlass.laminated") },
                    { id: 3, name: this.$t("product.findYourGlass.insulated") },
                    { id: 4, name: this.$t("product.findYourGlass.laminatedInsulated") },
                  ],
                },
                {
                  id: 2,
                  name: "อาคารขนาดสูง",
                  list_glass: [
                    { id: 2, name: this.$t("product.findYourGlass.laminated") },
                    { id: 3, name: this.$t("product.findYourGlass.insulated") },
                    { id: 4, name: this.$t("product.findYourGlass.laminatedInsulated") },
                  ],
                },
              ],
              show_glass: [
                { id: 1, name: this.$t("product.findYourGlass.sheet") },
                { id: 2, name: this.$t("product.findYourGlass.laminated") },
                { id: 3, name: this.$t("product.findYourGlass.insulated") },
                { id: 4, name: this.$t("product.findYourGlass.laminatedInsulated") },
              ],
            show_building: [
                { name: "อาคารแนวราบขนาดเล็ก/อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ", img: require("@/assets/img/findyourglass/build/build_1.png") },
                { name: "อาคารแนวราบขนาดเล็ก", img: require("@/assets/img/findyourglass/build/build_3.png") },
                { name: "อาคารสูง/ขนาดใหญ่/ขนาดใหญ่พิเศษ", img: require("@/assets/img/findyourglass/build/build_2.png") },
            ],
            type_color: [
                { id: 1, name: this.$t("product.findYourGlass.clear"), color: "white-normal" },
                { id: 2, name: this.$t("product.findYourGlass.green"), color: "green-lime" },
                { id: 3, name: this.$t("product.findYourGlass.darkGrey"), color: "orange-normal" },
                { id: 4, name: this.$t("product.findYourGlass.skyBlue"), color: "sky-normal" },
                { id: 5, name: this.$t("product.findYourGlass.grey"), color: "gray-dark" },
                { id: 6, name: this.$t("product.findYourGlass.bronzeGold"), color: "yellow-light" },
                { id: 7, name: this.$t("product.findYourGlass.other"), color: "black-normal" },
              ],
              range_protection: [
                {
                  id: 1,
                  name: this.$t("product.findYourGlass.properLight"),
                  img: require("@/assets/img/Home/icon/icon-type-sun.png"),
                  remark: "Proper Light > 30%",
                },
                {
                  id: 2,
                  name: this.$t("product.findYourGlass.properHeatProtection"),
                  img: require("@/assets/img/Home/icon/icon-type-hot.png"),
                  remark: "Proper Heat Protection > 45%",
                },
              ],
              protection: [
                {
                  id: 1,
                  name: this.$t("product.findYourGlass.safety"),
                  img: require("@/assets/img/Home/icon/icon-type-protection.png"),
                },
                {
                  id: 2,
                  name: this.$t("product.findYourGlass.UVProtection"),
                  img: require("@/assets/img/Home/icon/icon-type-uv.png"),
                },
                {
                  id: 3,
                  name: this.$t("product.findYourGlass.noiseReducer"),
                  img: require("@/assets/img/Home/icon/icon-type-sound.png"),
                },
              ],
              list_protection: [
                {
                  id: 1,
                  name: this.$t("product.findYourGlass.safety"),
                  img: require("@/assets/img/findyourglass/i_protection/i_1.png"),
                },
                {
                  id: 2,
                  name: this.$t("product.findYourGlass.UVProtection"),
                  img: require("@/assets/img/findyourglass/i_protection/i_2.png"),
                },
                {
                  id: 3,
                  name: this.$t("product.findYourGlass.noiseReducer"),
                  img: require("@/assets/img/findyourglass/i_protection/i_3.png"),
                },
              ],
            value_of_range_glass: [
                { id: 1, name_display: "<-20", name_show: "<-20%" },
                { id: 2, name_display: "21-30", name_show: "21-30%" },
                { id: 3, name_display: "31-40", name_show: "31-40%" },
                { id: 4, name_display: "41-50", name_show: "41-50%" },
                { id: 5, name_display: "51-60", name_show: "51-60%" },
                { id: 6, name_display: "61->", name_show: "61%->" },
            ],
            list_result_glass: [
            ],
            result: [],
            toggleEnable: false,
            showVideo: null,
            category_product: [],
            type_product: [],
            product_compare: [],
            advance: false,
            baseFront: this.$baseFront
        }
    },
    methods: {
          gotoPage(url){
            location.href = url;
        },

        clear_search() {
            this.list_build = [
                {
                  id: 1,
                  name: this.$t("product.findYourGlass.lowBuilding"),
                  img: require("@/assets/img/Home/i-build-sm.png"),
                  width_icon: "40",
                  type_glass: [],
                  type_color: [],
                  range_protection: [
                    { id: 1, name: this.$t("product.findYourGlass.properLight"), value: 3 },
                    { id: 2, name: this.$t("product.findYourGlass.properHeatProtection"), value: 4 },
                  ],
                  protection: [
                    { id: 1, name: this.$t("product.findYourGlass.safety"), value: true },
                    { id: 2, name: this.$t("product.findYourGlass.UVProtection"), value: false },
                    { id: 3, name: this.$t("product.findYourGlass.noiseReducer"), value: true },
                  ],
                },
                {
                  id: 2,
                  name: this.$t("product.findYourGlass.highBuilding"),
                  img: require("@/assets/img/Home/i-build-lg.png"),
                  width_icon: "30",
                  type_glass: [],
                  type_color: [],
                  range_protection: [
                    { id: 1, name: this.$t("product.findYourGlass.properLight"), value: 3 },
                    { id: 2, name: this.$t("product.findYourGlass.properHeatProtection"), value: 4 },
                  ],
                  protection: [
                    { id: 1, name: this.$t("product.findYourGlass.safety"), value: true },
                    { id: 2, name: this.$t("product.findYourGlass.UVProtection"), value: false },
                    { id: 3, name: this.$t("product.findYourGlass.noiseReducer"), value: true },
                  ],
                },
              ];
            this.advance = false;
            this.result = [];
        },
        gotoContact() {
            this.$router.push({ path: '/contact-us', query: { message: true } })
        },
        gotoCompare(item) {
            this.$router.push({ path: '/compare', query: { current_1: item.id } })
        },
        gotoCompare_2() {
            this.$router.push({ path: '/compare', query: { current_1: this.current_color1.id, current_2: this.current_color2.id } })
        },
        gotoDetail(id) {
            this.$router.push(`product-content/${id}`)
        },
        async fetchCategoryProduct() {
            await this.$axios.get("/api/show/category_product")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.category_product = response.data.data

                    }
                })
        },
        async fetchtypeProduct() {
            await this.$axios.get("/api/show/type_product")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.type_product = response.data.data

                    }
                })
        },
        async fetchProduct() {
            this.category_product = this.category_product.map(obj => ({ ...obj, list: [] }))
            this.type_product.map(i => {
                let ref = this.category_product.find(r => r.id === i.ref_category_product_id)
                if (ref) {
                    ref.list.push(i)
                    ref.list.sort((a, b) => a.sort - b.sort); // Sort the list by "sort" id
                }
                return ref
            })
            console.log(this.category_product);
        },
        openLink(url) {
            console.log(url);
            window.open(url, '_blank')
        },
        async fetchProduct_all() {
            await this.$axios.get("/api/show/product")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.product_compare = response.data.data;
                        this.list_result_glass = response.data.data;
                        this.current_color1 = this.product_compare.find(i=>i.id == 21);
                        this.current_color2 = this.product_compare.find(i=>i.id == 21);
                    }
                })
        },
        toggleButton(id) {
            if (id == 1) {
                document.getElementById("find-your-glass-open-2").classList.remove("show");
            } else {
                document.getElementById("find-your-glass-open-1").classList.remove("show");
            }
            this.$refs['FYGBtn' + id][0].focus()
        },
        CloseCollapse(id) {
            this.$refs['FYG' + id][0].focus()
        },
        search_glass(data) {
            this.$router.push({ path: "/findyourglass", query: { findyourglass: data, advance: this.advance } });
            // if (data.id == 1) {
            //     this.result = [];
            //     let filter_type_glass = [];
            //     let filter_type_color = [];
            //     let filter_vlt = [];
            //     let filter_shgc = [];
            //     let protect_1 = data.protection[0].value ? 1:0;
            //     let protect_2 = data.protection[1].value ? 1:0;
            //     let protect_3 = data.protection[2].value ? 1:0;
            //     // ค้นหาประเภทกระจก
            //     if (data.type_glass.length > 0) {
            //         filter_type_glass = this.list_result_glass.filter((i) =>
            //           data.type_glass.includes(i.type_glass)
            //         );
            //       } else {
            //         filter_type_glass = this.list_result_glass;
            //       }
            //       // ค้นหาโทนสี
            //     if (data.type_color.length > 0) {
            //       filter_type_color = filter_type_glass.filter((i) => {
            //         if (JSON.parse(i.type_color).length == 1) {
            //           return data.type_color.includes(
            //             JSON.parse(i.type_color)[0].color
            //           );
            //         } else if (JSON.parse(i.type_color).length > 1) {
            //           return (
            //             data.type_color.includes(JSON.parse(i.type_color)[0].color) ||
            //             data.type_color.includes(JSON.parse(i.type_color)[1].color)
            //           );
            //         }
            //       });
            //     } else {
            //       filter_type_color = filter_type_glass;
            //     }
            //     if(this.advance) {
            //         filter_vlt = filter_type_color.filter(i => {
            //             if (data.range_protection[0].value == 1) {
            //                 return parseFloat(i.vlt) <= 20
            //             } else if (data.range_protection[0].value == 2) {
            //                 return parseFloat(i.vlt) >= 21 && parseFloat(i.vlt) <= 30
            //             } else if (data.range_protection[0].value == 3) {
            //                 return parseFloat(i.vlt) >= 31 && parseFloat(i.vlt) <= 40
            //             } else if (data.range_protection[0].value == 4) {
            //                 return parseFloat(i.vlt) >= 41 && parseFloat(i.vlt) <= 50
            //             } else if (data.range_protection[0].value == 5) {
            //                 return parseFloat(i.vlt) >= 51 && parseFloat(i.vlt) <= 60
            //             } else if (data.range_protection[0].value == 6) {
            //                 return parseFloat(i.vlt) >= 61
            //             }
            //         })
    
            //         // ค้นหาระดับการป้องกันความร้อน
            //         filter_shgc = filter_vlt.filter(i => {
            //             if (data.range_protection[1].value == 1) {
            //                 return parseFloat(i.shgc) <= 20
            //             } else if (data.range_protection[1].value == 2) {
            //                 return parseFloat(i.shgc) >= 21 && parseFloat(i.shgc) <= 30
            //             } else if (data.range_protection[1].value == 3) {
            //                 return parseFloat(i.shgc) >= 31 && parseFloat(i.shgc) <= 40
            //             } else if (data.range_protection[1].value == 4) {
            //                 return parseFloat(i.shgc) >= 41 && parseFloat(i.shgc) <= 50
            //             } else if (data.range_protection[1].value == 5) {
            //                 return parseFloat(i.shgc) >= 51 && parseFloat(i.shgc) <= 60
            //             } else if (data.range_protection[1].value == 6) {
            //                 return parseFloat(i.shgc) >= 61
            //             }
            //         })
    
            //         // ค้นหาความปลอดภัย
            //         this.result = filter_shgc.filter(i => i.protect_1 == protect_1 && i.protect_2 == protect_2 && i.protect_3 == protect_3 && (i.type_build == 2 || i.type_build == 1));
            //     } else {
            //         this.result = filter_type_color;
            //     }
            //     //ค้นหาระดับแสงส่องผ่านกระจก
                
            //     if(this.result.length == 0) {
            //         Swal.fire({
            //             icon: 'error',
            //             title: 'ผลการค้นหา',
            //             text: 'ไม่พบข้อมูลกระจกที่คุณค้นหา',
            //         })
            //     }
            // } else if (data.id == 2) {
            //     this.result = [];
            //     let filter_type_glass = [];
            //     let filter_type_color = [];
            //     let filter_vlt = [];
            //     let filter_shgc = [];
            //     let protect_1 = data.protection[0].value ? 1:0;
            //     let protect_2 = data.protection[1].value ? 1:0;
            //     let protect_3 = data.protection[2].value ? 1:0;
            //     // ค้นหาประเภทกระจก
            //     if (data.type_glass.length > 0) {
            //       filter_type_glass = this.list_result_glass.filter((i) =>
            //         data.type_glass.includes(i.type_glass)
            //       );
            //     } else {
            //       filter_type_glass = this.list_result_glass;
            //     }
            //     // ค้นหาโทนสี
            //     if (data.type_color.length > 0) {
            //       filter_type_color = filter_type_glass.filter((i) => {
            //         if (JSON.parse(i.type_color).length == 1) {
            //           return data.type_color.includes(
            //             JSON.parse(i.type_color)[0].color
            //           );
            //         } else if (JSON.parse(i.type_color).length > 1) {
            //           return (
            //             data.type_color.includes(JSON.parse(i.type_color)[0].color) ||
            //             data.type_color.includes(JSON.parse(i.type_color)[1].color)
            //           );
            //         }
            //       });
            //     } else {
            //       filter_type_color = filter_type_glass;
            //     }
            //     //ค้นหาระดับแสงส่องผ่านกระจก
            //     if(this.advance) {
            //         filter_vlt = filter_type_color.filter(i => {
            //             if (data.range_protection[0].value == 1) {
            //                 return parseFloat(i.vlt) <= 20
            //             } else if (data.range_protection[0].value == 2) {
            //                 return parseFloat(i.vlt) >= 21 && parseFloat(i.vlt) <= 30
            //             } else if (data.range_protection[0].value == 3) {
            //                 return parseFloat(i.vlt) >= 31 && parseFloat(i.vlt) <= 40
            //             } else if (data.range_protection[0].value == 4) {
            //                 return parseFloat(i.vlt) >= 41 && parseFloat(i.vlt) <= 50
            //             } else if (data.range_protection[0].value == 5) {
            //                 return parseFloat(i.vlt) >= 51 && parseFloat(i.vlt) <= 60
            //             } else if (data.range_protection[0].value == 6) {
            //                 return parseFloat(i.vlt) >= 61
            //             }
            //         })
    
            //         // ค้นหาระดับการป้องกันความร้อน
            //         filter_shgc = filter_vlt.filter(i => {
            //             if (data.range_protection[1].value == 1) {
            //                 return parseFloat(i.shgc) <= 20
            //             } else if (data.range_protection[1].value == 2) {
            //                 return parseFloat(i.shgc) >= 21 && parseFloat(i.shgc) <= 30
            //             } else if (data.range_protection[1].value == 3) {
            //                 return parseFloat(i.shgc) >= 31 && parseFloat(i.shgc) <= 40
            //             } else if (data.range_protection[1].value == 4) {
            //                 return parseFloat(i.shgc) >= 41 && parseFloat(i.shgc) <= 50
            //             } else if (data.range_protection[1].value == 5) {
            //                 return parseFloat(i.shgc) >= 51 && parseFloat(i.shgc) <= 60
            //             } else if (data.range_protection[1].value == 6) {
            //                 return parseFloat(i.shgc) >= 61
            //             }
            //         })
    
            //         // ค้นหาความปลอดภัย
            //         this.result = filter_shgc.filter(i => i.protect_1 == protect_1 && i.protect_2 == protect_2 && i.protect_3 == protect_3 && (i.type_build == 3 || i.type_build == 1));
            //         console.log(this.result);
            //     } else {
            //         this.result = filter_type_color;
            //     }
                
            //     if(this.result.length == 0) {
            //         Swal.fire({
            //             icon: 'error',
            //             title: 'ผลการค้นหา',
            //             text: 'ไม่พบข้อมูลกระจกที่คุณค้นหา',
            //         })
            //     }
            // }
            console.log(data);
        },
        search_glass_all(data) {
            if (data.id == 1) {
                this.result = [];
                // ค้นหาความปลอดภัย
                this.result = this.list_result_glass.filter(i => i.type_build == 2 || i.type_build == 1);
            } else if (data.id == 2) {
                this.result = [];
                // ค้นหาความปลอดภัย
                this.result = this.list_result_glass.filter(i => i.type_build == 3 || i.type_build == 1);
            }
            console.log(this.result);
        },

    },
    async mounted() {
        await this.fetchCategoryProduct()
        await this.fetchtypeProduct()
        await this.fetchProduct();
        await this.fetchProduct_all()
        var swiper = new Swiper(".swiper", {
            slidesPerView: 2,
            spaceBetween: 10,
            pauseOnMouseEnter: false,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30
                }
            },
        });
        document.title = "AGC - Product";
        AOS.init();
        resize();
        function resize() {
            if (window.innerWidth < 800) $('.footer').css({ 'position': 'relative', 'top': '0px' });
        }
        // this.product_list = await apiGet(product,{
        //     page : 1,
        //     limit : 100000000,
        //     sort : 'ASC',
        // })
    },
    components: {
        Footer, Header, Breadcrumb,Toolbar
    }
};